import CustomMarkdown from '@/molecules/CustomMarkdown';

type Props = {
  children: string;
  subtitle: string;
};

const BigTitleBox = ({ children, subtitle }: Props) => (
  <div className="px-4 md:px-0">
    <p className="bg-gradient-to-l from-transparent to-transparent via-[#FFD789] title-1 text-white text-shadow-lg shadow-black text-center py-4 mb-4">
      {children}
    </p>
    {subtitle && <CustomMarkdown className="lg-medium text-neutral-400 text-center">{subtitle}</CustomMarkdown>}
  </div>
);

export default BigTitleBox;
